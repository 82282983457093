<template>
  <a-spin :spinning="spinning">
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <OrderSearch :showOrderStatus="'delivery_order'" @getSearchData="getSearchData" ref="OrderSearch"></OrderSearch>
      <div>
        <!-- <a-button v-if="isAdd" type="primary" @click="toHandler('add')">新增</a-button> -->
        <!-- <a-button v-if="isEditAddress && selectedRowKeys.length > 0" type="primary" @click="toHandler('edit_address')">修改地址</a-button> -->
        <!-- <a-button v-if="isEditInvoice && selectedRowKeys.length > 0" type="primary" @click="toHandler('edit_invoice')">修改发票</a-button> -->
        <!-- <a-button v-if="isEdit && selectedRowKeys.length > 0" type="primary" @click="toHandler('edit')">修改商品</a-button> -->
<!--        <a-button v-if="isAudit && selectedRows.length > 0" type="primary" @click="toHandler('audit')">审核</a-button>-->
<!--        <a-button v-if="isVoid && selectedRows.length > 0" type="primary" @click="toHandler('void')">关闭订单</a-button>-->
<!--        <a-button v-if="isCancel && selectedRows.length > 0" type="primary" @click="toHandler('cancel')"-->
<!--          >取消订单</a-button-->
<!--        >-->
        <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button>
        <a-button v-if="isVoid && selectedRows.length == 1 && selectedRows[0].orderStatus == 5" type="primary" @click="toHandler('void')">
          关闭订单</a-button>
        <a-button v-if="isCancel && selectedRows.length == 1 && selectedRows[0].shipCount <= 0 && selectedRows[0].orderStatus != 5" type="primary" @click="toHandler('cancel')">
          取消订单</a-button
        >
        <downLoad
          method="get"
          api="/api/order/order/orderInfo/exportSingleOrderInfo"
          :params="{id:selectedRows[0].id}"
          name="发货单.xlsx"
          v-if="selectedRows.length == 1"
          @downLoadDone="downLoadDone"
          @startDownLoad="startDownLoad"
        >导出发货单</downLoad
        >
        <!--        isExportSingleShipOrderInfo &&-->
        <!-- <downLoad
          v-if="isExport"
                  method="post"
                  api="/api/order/order/orderInfo/excelExportOrderInfo"
                  :params="searchDataObj"
                  @downLoadDone="downLoadDone"
                  @startDownLoad="startDownLoad"
          name="菱感销售单(已发货).xls" >批量导出订单</downLoad> -->
        <a-button 
        name="菱感销售单.xls"
        @click="getDownLoadDone"
        v-if="isExport">
        批量导出订单</a-button>
      </div>
      <!-- 数据表格 -->
      <OrderList
        :url="'/api/order/order/orderInfo/list'"
        :columnsData="columns"
        :status="[5, 6]"
        :text="'delivery'"
        @orderData="getSelectedRows($event)"
        @orderDataKey="getKeys"
        @changeTable="getchangeTable"
      ></OrderList>
      <!-- 编辑表单 -->
      <OrderInfo-edit-modal ref="OrderInfoEditModal"></OrderInfo-edit-modal>
      <EditAddressModal ref="EditAddressModal"></EditAddressModal>
      <InvoiceEditModal ref="InvoiceEditModal"></InvoiceEditModal>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
      <a-modal
        title="操作"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
        :maskClosable="false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="原因">
            <a-textarea v-model="rejectReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">关闭</a-button>
          <a-button v-if="handle == 'void'" type="primary" @click="onModifyOrderStatus('void')">关闭订单</a-button>
          <a-button v-if="handle == 'cancel'" type="primary" @click="onModifyOrderStatus('cancel')">取消订单</a-button>
          <a-button v-if="handle == 'audit'" type="danger" @click="onModifyOrderStatus('refuse')">拒绝</a-button>
          <a-button v-if="handle == 'audit'" type="primary" @click="onModifyOrderStatus('audit')">通过</a-button>
        </div>
      </a-modal>
      <a-modal
        title="请选择导出列"
        width="50%"
        :visible="isShowLoading"
        @cancel="handleCancel"
        :footer="null"
        >
          <div class="warpper-radio">
              <div class="all-check">
                <section>
                  <span>全选：</span>
                  <a-checkbox  :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange"></a-checkbox>
                </section>
              </div>
              <div class="list-serial-number">
                <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="onChange" />
              </div>
              <br>
              <hr>
              <div class="footer-bts">
              <a-button @click="handleCancel">取消</a-button>
              <downLoad
                method="post"
                api="/api/order/order/orderInfo/excelExportOrderInfo"
                :params="searchDataObj"
                @downLoadDone="downLoadDone"
                @startDownLoad="startDownLoad"
                :isConfirmLoading="isConfirmLoading"
                :type="primary"
                name="菱感销售单.xls"
                v-if="isExport"
                >导出</downLoad>
              </div>
          </div>
      </a-modal>
    </div>
  </a-card>
  </a-spin>
</template>

<script>
import OrderInfoEditModal from '@/views/order_info/components/OrderInfoEditModal.vue'
import { delOrderInfo, listOrderInfo, selectByIdOrderInfo } from './api/OrderInfoApi'
import EditAddressModal from './components/EditAddressModal.vue'
import InvoiceEditModal from './components/InvoiceEditModal.vue'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'orderInfo',
  components: {
    OrderInfoEditModal,
    EditAddressModal,
    InvoiceEditModal,
    OrderInfoCheckModal,
  },
  data() {
    return {
      columns: [
        'orderCode',
        'bigAreaName',
        'dealerName',
        'belongDealerName',
        'flagFreeShipping',
        'deliveryProperties',
        'purchaseAndSalesContractType',
        'contractType',
        'orderType',
        'sourceType',
        'payAmount',
        'orderStatus',
        'expectTime',
        'createTime',
        'orderDate',
        'address',
        'consigneeType',
        'supplierName',
        'policyTotalAmount',
        'rebateTotalAmount',
        'shipOrderType',
        'linkGapContractNum',
        'stContractNum',
        'bucklePoint',
        'alreadySendGoodsCount',
        'goodsCount',
      ],
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isCheck: checkPermission('order:info:check_delivery'),
      isExport: checkPermission('order:info:export_delivery'),
      isCancel: checkPermission('order:info:cancel_delivery'),
      isVoid: checkPermission('order:info:void_delivery'),
      visible: false,
      confirmLoading: false,
      rejectReason: '',
      handle: '',
      contractTypeList: [],
      orderTypeList: [],
      idsList:[],
      spinning:false,
      primary:'primary',
      isConfirmLoading:false,
      isShowLoading: false,
      checkAll: false,
      indeterminate: true,
      checkedList:[],
      defaultCheckedList:['序号', '订单编号', '订单状态', '客户名称', '大区', '事务所', '发货性质', '购货方式', '订单类型', '订单日期', '订单备注','商品编码', '商品名称', '规格型号', '商品分类', '品牌', '标签', '数量','已发数量','未发数量', '单位', '标准开票价（D价）','政策单价','折扣额',  '实际成交价',  '标准开票价金额','实付金额', '机型', '备注', '销售合同号', '采购合同号', '收货人信息'],
      plainOptions:['序号', '订单编号', '订单状态', '客户名称', '大区', '事务所', '发货性质', '购货方式', '订单类型', '订单日期', '订单备注','商品编码', '商品名称', '规格型号', '商品分类', '品牌', '标签', '数量','已发数量','未发数量', '单位', '标准开票价（D价）','政策单价','折扣额',  '实际成交价',  '标准开票价金额','实付金额', '机型', '备注', '销售合同号', '采购合同号', '收货人信息']
    }
  },
  computed:{
    searchDataObj(){
      let obj = {
        column:[JSON.parse(JSON.stringify(this.checkedList))],
        ...this.searchData,
        ids:this.idsList
      }
      return obj

    }
  },
  methods: {
    // 批量导出
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
    handleCancel() {
      this.isShowLoading = false;
    },
    getDownLoadDone() {
      this.checkedList = this.defaultCheckedList
      this.isShowLoading = true;
    },
    //
    startDownLoad(){
      this.isConfirmLoading = true;
      this.spinning = true
    },
    downLoadDone (){
      this.isConfirmLoading = false;
      this.isShowLoading = false;
      this.spinning = false
    },
    getchangeTable(){
      this.$refs.OrderSearch.getDataFilter(1)
    },
    // 导出条件
    getSearchData(row){
     this.searchData = row
     this.searchData.statusList = [5,6]
     this.idsList = []
    },
    getKeys(row){
     this.idsList = row
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    getSelectedRows(rows) {
      this.selectedRows = rows
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.OrderInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.OrderInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.$refs.OrderSearch.getDataFilter()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'edit_address':
          _this.$refs.EditAddressModal.isShow(_this.selectedRows[0])
          break
        case 'edit_invoice':
          _this.$refs.InvoiceEditModal.isShow(_this.selectedRows[0])
          break
        case 'audit':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'audit')
          break
        case 'void':
          _this.visible = true
          _this.handle = 'void'
          break
        case 'cancel':
          _this.visible = true
          _this.handle = 'cancel'
          break
        case 'check':
          this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0],'','delivery')
          break
      }
    },

    // 修改订单状态
    onModifyOrderStatus(name) {
      let status = 0
      if (name == 'audit') {
        status = 4
      } else if (name == 'refuse') {
        status = 3
      } else if (name == 'void') {
        status = 1
      } else if (name == 'cancel') {
        status = 2
      }
      let obj = {
        orderId: this.selectedRows[0].id,
        orderStatus: status,
        verifyRemark: this.rejectReason,
      }
      this.axios.post(`/api/order/order/orderInfo/verifyOrderInfo`, obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.$refs.OrderSearch.getDataFilter()
          this.rejectReason = ''
          this.selectedRowKeys = []
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
::v-deep .ant-checkbox-group .ant-checkbox-group-item {
  width: 180px;
}
.footer-bts {
  text-align: right;
}
</style>
